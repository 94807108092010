
import FlexBannerComponentVue from '@/components/FlexBannerComponent.vue';
<template>
    <FlexBanner />
    <div class="w-100 p-4 p-lg-5 p-start text-start paragraph position-relative">
        <div class="p-lg-4 py-4 ms-lg-4 left">
            <div class="row mt-4">
                <div class="col-md-6 col-lg-3">
                    <h2 class="border-0 d-none d-lg-block">Menu</h2>
                    <AdminNav/>
                </div>
                <div class="px-lg-5 col-lg-9">
                    <h1>Beelden beheren</h1>
                    <p class="paragraph-text text-white">Hier kun je gemakkelijk alle beelden beheren op je website. Je kunt hier ook een categorie aan ze toekennen om ze te groeperen.</p>

                    <h2 class="border-0 mt-5">Nieuw beeld toevoegen</h2>
                    <div @click="addNewSculpture" class="btn btn-gold fw-bold"><i class="fa fa-plus me-1"></i>Nieuw beeld toevoegen</div>

                    <h2 class="border-0 mt-5">Alle beelden</h2>
                    <div class="row mb-3">
                        <div class="col-md-6 col-lg-3 mb-3">
                            <select class="form-select" id="sortSculptures">
                                <option value="">-- Sorteren --</option>
                                <option value="newest-first">Nieuwste eerst</option>
                                <option value="oldest-first">Oudste eerst</option>
                                <option value="alpha-asc">Naam A-Z</option>
                                <option value="alpha-desc">Naam Z-A</option>
                            </select>
                        </div>
                        <div class="col-md-6 col-lg-3">
                            <div class="input-group">
                                <input type="text" class="form-control" id="searchInput" placeholder="Zoek een beeld" @keyup.enter="fetchSculptures">
                                <div class="input-group-append">
                                    <span class="input-group-text h-100" @click="fetchSculptures"><i class="fa fa-magnifying-glass"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template v-if="sculptures.length">
                        <table class="text-white paragraph-text">
                            <thead>
                                <tr>
                                    <th>Foto</th>
                                    <th class="px-4">Naam</th>
                                    <th class="px-4">Categorie</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="s in sculptures" :key="s.id" class="border-bottom border-subtle" role="button" @click="() => editExistingScuplture(s)">
                                    <td class="py-2">
                                        <img :src="`https://vorm-vrij.nl/upload/foto/${s.direct_image_url}`" class="thumbnail">
                                    </td>
                                    <td class="py-2 px-4 w-100">{{ s.name }}</td>
                                    <td class="py-2 px-4 w-100">{{ categoryNames[s.category_id] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                    <template v-else>
                        <div class="w-100 bg-warning p-3 rounded-2 fw-bold">Er zijn geen beelden gevonden</div>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <AdminModal :purpose="currentModal?.purpose" :title="currentModal?.title" :description="currentModal?.description"
        :width="currentModal?.width" :height="currentModal?.height" :direct_image_url="currentModal?.direct_image_url"
        :openModal="openModal" @closeModal="closeModal" :category_id="currentModal?.category_id" :is_cursistenwerk="currentModal?.is_cursistenwerk"
        :id="currentModal?.id" :visible="currentModal?.visible" />
</template>

<script type="text/javascript">
import $ from 'jquery';

import FlexBanner from '@/components/FlexBannerComponent.vue';
import AdminNav from '@/components/AdminNavComponent.vue';
import AdminModal from '@/components/AdminModalComponent.vue';
export default {
    name: 'admin-dashboard',
    components: {
        FlexBanner,
        AdminNav,
        AdminModal,
    },
    data() {
        return {
            sculptures: [],
            openModal: false,
            currentModal: undefined,
            categoryNames: [],
        }
    },
    async mounted() {
        await this.fetchSculptures();

        $("#sortSculptures").on('change', () => {
            let sort = $("#sortSculptures").val();
            if(sort !== "") {
                this.fetchSculptures();
            }
        });
    },
    methods: {
        async fetchSculptures() {
            let getSort = "";
            switch($("#sortSculptures").val()) {
                case "newest-first":
                    getSort = `?sorteer=id&way=desc`;
                    break;
                case "oldest-first":
                    getSort = `?sorteer=id&way=asc`;
                    break;
                case "alpha-desc":
                    getSort = `?sorteer=name&way=desc`;
                    break;
                case "alpha-asc":
                    getSort = `?sorteer=name&way=asc`;
                    break;
            }

            let search = $("#searchInput").val();
            if(search.length)
                getSort += (getSort.length ? "&" : "?") + `search=${search}`;

            await fetch('https://vorm-vrij.nl/scripting/get_all_sculptures.php' + getSort).then((response) => {
                return response.json();
            }).then((res) => {
                this.sculptures = res;
            }).catch((err) => {
                console.log(err);
            });

            await fetch('https://vorm-vrij.nl/scripting/get_categories.php').then((response) => {
                return response.json();
            }).then((res) => {
                for(let i = 0; i < res.length; i++) {
                    this.categoryNames[res[i].id] = res[i].name;
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        addNewSculpture() {
            this.openModal = true;
            this.currentModal = {
                title: "Nieuw beeld",
                purpose: "add_sculpture",
            }
        },
        editExistingScuplture(sculpture) {
            this.openModal = true;
            this.currentModal = sculpture;
            this.currentModal.purpose = "edit_sculpture";
            this.currentModal.title = sculpture.name;
        },
        closeModal() {
            this.openModal = false;
        },
    },
}
</script>

<style scoped>
.border-subtle {
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>